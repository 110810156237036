<template>
  <v-container class="PromotionProgram">

    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('succesfully')"
    :headerMsg="$t('succesfully')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
    :onHandlerYes="buttonOke"/>


    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="$t('failed')"
    :headerMsg="$t('failed')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    :onHandlerYes="buttonOkeFailed"/>

      <v-row  no-gutters >
        <v-col>
          <div >
           <h4>
          {{$t('ActivePromotionProgram')}}</h4> 

          <v-card v-if="notifAvailableUnderAged == false" outlined class="secondary lighten-5 ma-4 rounded-card onHover" @click="clickPromotionProgram()" >
            <v-list class="transparent div-template">

              <h4>{{$t('UnderAgedStudentPromotion')}}</h4>
              <v-input
                :success-messages="pinActiveUnderAged"
                success
                disabled
              >
                PIN
              </v-input>
              <v-list-item>
              </v-list-item>
              <v-btn
                color="primary"
                small
              >{{$t('ChangePIN')}}</v-btn>
                
                <v-btn
                color="primary"
                small
              >{{$t('Unregister')}}</v-btn>
              
            </v-list>
          </v-card>


          <v-card v-if="notifAvailableStudent == false" outlined class="secondary lighten-5 ma-4 rounded-card onHover" >
            <v-list class="transparent div-template">
              <h4>{{$t('StudentPromotion')}}</h4>
              <v-input
                :success-messages="pinActiveStudent"
                success
                disabled
              >
                PIN
              </v-input>
              
              <v-list-item>
              </v-list-item>
              <v-btn
                color="primary"
                small
              >{{$t('ChangePIN')}}</v-btn>
                <v-btn
                color="primary"
                small
              >{{$t('Unregister')}}</v-btn>

            </v-list>
          </v-card>

          <v-card v-if="notifAvailableTransit == false" outlined class="secondary lighten-5 ma-4 rounded-card onHover" >
            <v-list class="transparent div-template">
              <h4>{{$t('TransitPromotionFare')}}</h4>
              <v-input
                :success-messages="pinActiveTransit"
                success
                disabled
              >
                PIN
              </v-input>
              
              <v-list-item>
              </v-list-item>
              <v-btn
                color="primary"
                small
              >{{$t('ChangePIN')}}</v-btn>
                
                <v-btn
                color="primary"
                small
              >{{$t('Unregister')}}</v-btn>
            </v-list>
          </v-card>

          <v-card v-if="notifdontActivePromotion == true" outlined class="secondary lighten-5 ma-4 rounded-card onHover" @click="clickPromotionProgram()" >
            <v-list class="transparent div-template">
                <p>{{$t('dontActivePromotion')}}</p>
            </v-list>
          </v-card>

          </div>
        
          <div >
           <h4>
          {{$t('AvailablePromotions')}}</h4> 

          <v-card v-if="notifAvailableUnderAged == true" outlined class="secondary lighten-5 ma-4 rounded-card onHover" @click="clickPromotionProgram()" >
            <v-list class="transparent div-template">
              <h4>{{$t('UnderAgedStudentPromotion')}}</h4>
              <v-list-item>
              </v-list-item>
             <!-- <ac-button 
              :name="$t('Register')" 
              :color="primary"
              :onClickHandler="clickUnderAgedStudentPromotion"/> -->
                <v-btn
                color="primary"
                small
                @click="clickUnderAgedStudentPromotion()"
              >{{$t('Register')}}</v-btn>
            </v-list>
          </v-card>

          <v-card v-if="notifAvailableStudent == true" outlined class="secondary lighten-5 ma-4 rounded-card onHover" >
            <v-list class="transparent div-template">
              <h4>{{$t('StudentPromotion')}}</h4>
             <!-- <ac-button 
              :name="$t('Register')" 
              :color="primary"
              :onClickHandler="clickStudentPromotion"/> -->

                <v-btn
                color="primary"
                small
                @click="clickStudentPromotion()"
              >{{$t('Register')}}</v-btn>
            </v-list>
          </v-card>

          <v-card v-if="notifAvailableTransit == true" outlined class="secondary lighten-5 ma-4 rounded-card onHover" >
            <v-list class="transparent div-template">
              <h4>{{$t('TransitPromotionFare')}}</h4>
             <!-- <ac-button 
              :name="$t('Register')" 
              :color="primary"
              :onClickHandler="clickTransitPromotionFare"/> -->

                <v-btn
                color="primary"
                small
                @click="clickTransitPromotionFare()"
              >{{$t('Register')}}</v-btn>
            </v-list>
          </v-card>

          </div>

        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import randomstring from 'randomstring'
// import AcButton from '../../components/AcButton.vue'
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name:"PromotionProgram",
    components: { NotificationPopup},
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,

        notifdontActivePromotion:true,
        notifAvailableUnderAged:true,
        notifAvailableStudent:true,
        notifAvailableTransit:true,
        pinActiveUnderAged: "",
        pinActiveStudent: "",
        pinActiveTransit: "",
        typePromo:[],
        dataTypePromo:[
          {promo_id: "57", promo_name: "Under-Aged Student Promotion"},
          {promo_id: "58", promo_name: "Student Promotion"},
          {promo_id: "59", promo_name: "Transit Promotion Fare"}
        ],
      }
    },
    methods:{
      clickUpdatePassword(){
        this.$router.push({
          name: 'ChangePassword',
        })
      },
      clickPromotionProgram(){
        this.$router.push({
          name: 'PromotionProgram',
        })
      },
      dataType(){
        
        this.$store
        .dispatch("passenger/getTypePromo")
        .then((response) => {
          this.dataTypePromo = response
          // for (const key of response) {
          //   this.dataTypePromo.push(key);
          // }
        })
        .catch((err) => {
          console.log(err);
        });

        return this.dataTypePromo
      },
      clickUnderAgedStudentPromotion(){
        let random = randomstring.generate({
          length: 6,
          charset: 'alphabetic',
          capitalization:'uppercase',
        });

        const payload = {
            promo_id:"57",
            random_code:random,
        };

        this.$store
        .dispatch("passenger/createPromotionProgram", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true
          }
          // console.log(response)
        })
        .catch((err) => {
          console.log(err);
        });

      },
      clickTransitPromotionFare(){
        let random = randomstring.generate({
          length: 6,
          charset: 'alphabetic',
          capitalization:'uppercase',
        });

        const payload = {
            promo_id:"59",
            random_code:random,
        };

        this.$store
        .dispatch("passenger/createPromotionProgram", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true
          }
          // console.log(response)
        })
        .catch((err) => {
          console.log(err);
        });

      },

      clickStudentPromotion(){
        let random = randomstring.generate({
          length: 6,
          charset: 'alphabetic',
          capitalization:'uppercase',
        });

        const payload = {
            promo_id:"58",
            random_code:random,
        };

        this.$store
        .dispatch("passenger/createPromotionProgram", payload)
        .then((response) => {
          if (response.status == true) {
            this.notifSuccess = true
          }
          // console.log(response)
        })
        .catch((err) => {
          console.log(err);
        });

      },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) this.$router.push({ name: "About" });
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      
    },
    mounted(){
      

      this.$store
        .dispatch("passenger/getPromotion")
        .then((response) => {
            if (response.length > 0) {
              this.notifdontActivePromotion = false
             
            }
          for (const key of response) {
            if (key.psgp_promo_id == "57") {
              this.notifAvailableUnderAged = false 
              this.pinActiveUnderAged = key.psgp_code_promo
            }

            if (key.psgp_promo_id == "58") {
              this.notifAvailableStudent = false

              this.pinActiveStudent = key.psgp_code_promo
            }

            if (key.psgp_promo_id == "59") {
              this.notifAvailableTransit = false
              this.pinActiveTransit = key.psgp_code_promo
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
</script>


<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}
</style>